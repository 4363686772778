export enum AuthProviders {
    EmailAndPassword,
    Google,
    Discord,
    Twitch,
}

export interface IAuthUser {
    id: string
    email: string | null
    displayName: string | null
    photoURL: string | null
}