















































import Vue from 'vue'
import {Component} from "vue-property-decorator";
import {Getter} from "vuex-class";
import {AuthModuleGetterTypes} from "@/store/modules/auth/types";
import {StoreNamespace} from "@/store/types";
import {AuthProviders, IAuthUser} from "@/core/auth/types";
import {AccountModuleGetterTypes} from "@/store/modules/account/types";
import {IAccountEntity} from "@/core/accounts/account.entity";
import {PopupsStoreModule} from "@/store";
import {PopupType} from "@/core/popups/types";
import BaseButtonRounded from "@/components/Shared/Buttons/BaseButtonRounded.vue";

@Component({
  components: {BaseButtonRounded}
})
export default class UserSettingsAccountPage extends Vue {
  @Getter(AuthModuleGetterTypes.USER, { namespace: StoreNamespace.Auth })
  private readonly user!: IAuthUser

  @Getter(AccountModuleGetterTypes.ACCOUNT, { namespace: StoreNamespace.Account })
  private readonly account!: IAccountEntity

  get isCurrentUserCanEditEmail () {
    return this.account.default_provider === AuthProviders.EmailAndPassword
  }

  get isCurrentUserCanEditPassword () {
    return this.account.default_provider === AuthProviders.EmailAndPassword
  }

  private onEditDisplayNameAction () {
    PopupsStoreModule.showPopup({
      popupType: PopupType.UpdateAccountDisplayNamePopup
    })
  }

  private onEditEmailAction () {
    PopupsStoreModule.showPopup({
      popupType: PopupType.UpdateAccountEmailPopup
    })
  }

  private onEditPasswordAction () {
    PopupsStoreModule.showPopup({
      popupType: PopupType.UpdateAccountPasswordPopup
    })
  }
}
